import React from 'react';
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export function YesnodunnoControl({questionKey, answer, handleAnswer}) {
  return (
    <ButtonGroup toggle className="d-flex">
      <ToggleButton
        type="checkbox"
        variant={'1' !== answer && '0' !== answer ? "secondary" : "secondary"}
        checked={'1' !== answer && '0' !== answer}
        value=""
        onChange={e => handleAnswer(questionKey, e.currentTarget.value)}
      >Keine Angaben</ToggleButton>
      <ToggleButton
        type="checkbox"
        variant={'0' === answer ? "danger" : "secondary"}
        checked={'0' === answer}
        value="0"
        onChange={e => handleAnswer(questionKey, e.currentTarget.value)}
      >Nein</ToggleButton>
      <ToggleButton
        type="checkbox"
        variant={'1' === answer ? "success" : "secondary"}
        checked={'1' === answer}
        value="1"
        onChange={e => handleAnswer(questionKey, e.currentTarget.value)}
      >Ja</ToggleButton>
    </ButtonGroup>
  );
}
