import React from 'react';
import Form from "react-bootstrap/Form";

const valueMap = {
  1: 'irrelevant',
  2: 'geringerMehrwert',
  3: 'neutral',
  4: 'deutlicherMehrwert',
  5: 'unverzichtbar',
};

const valueReverseMap = Object.keys(valueMap).reduce((memo, key) => {
  memo[valueMap[key]] = key;
  return memo;
}, {});

export function RangeControl({questionKey, answer, handleAnswer}) {
  return (
    <div>
      <Form.Control
        type="range" custom value={valueReverseMap[answer] || ''}
        min="1" max="5" step="1"
        className="mb-1"
        onChange={e => handleAnswer(questionKey, valueMap[e.currentTarget.value])}
      />
      <div className="text-muted text-center font-weight-bold">
        {'irrelevant' === answer && <span className="text-muted">Irrelevant</span>}
        {'geringerMehrwert' === answer && <span className="text-muted">Geringer Mehrwert</span>}
        {('neutral' === answer || !answer) && <span className="text-body">Neutral</span>}
        {'deutlicherMehrwert' === answer && <span className="text-body font-weight-bold">Deutlicher Mehrwert</span>}
        {'unverzichtbar' === answer && <span className="text-body font-weight-bold">Unverzichtbar</span>}
      </div>
    </div>
  );
}
