import React from 'react';
import {ContactCTA} from "./ContactCTA";

export function IntroText({}) {
  return (
    <>
      <div className="row">
        <div className="col-md-6 col-lg-7 col-xl-8">
          <h1 className="mb-5 readable-30">
            <small>Projekt-Analyse</small><br/>
            Wie können Sie von einer individuellen Software-Lösung profitieren?
          </h1>
          <div className="mb-5">
            <p className="readable-65">
              Mit dem folgenden Fragebogen und den aus Ihren Antworten abgeleiteten Empfehlungen biete ich Ihnen eine
              erste Orientierungshilfe bei der Frage, ob bzw. wie Sie von einer individuellen Software-Lösung
              profitieren
              können.
            </p>
            <p className="readable-65">
              <strong>Ihre Antworten werden <i>nicht</i> automatisch übermittelt</strong> und verlassen Ihren
              Browser nicht.
            </p>
            <p className="readable-65">
              {' '}
              Sie haben jedoch am Ende des Vorgangs die Möglichkeit, Ihre Antworten zu übermitteln, falls Sie
              Interesse an weiteren Informationen oder einem verbindlichen Angebot haben.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-5 col-xl-4 pt-4">
          <div className="card card-body card-highlight mb-5">
            <ContactCTA/>
          </div>
        </div>
      </div>
    </>
  );
}
