import React from 'react';
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export function RadioControl({questionKey, answer, handleAnswer, options}) {
  return (
    <ButtonGroup vertical toggle className="d-flex">
      {Object.keys(options).map(optionValue => (
        <ToggleButton
          key={optionValue}
          type="checkbox"
          variant={optionValue === answer ? "success" : "secondary"}
          checked={optionValue === answer}
          value={optionValue}
          onChange={e => handleAnswer(questionKey, e.currentTarget.value)}
        >
          {options[optionValue]}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}
