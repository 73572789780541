import React, {useEffect} from 'react';
import {Reason} from "./Reason";
import {getRecommendations} from "./questionnaire";
import Card from "react-bootstrap/Card";
import {NopeButSorry} from "./NopeButSorry";
import {ContactCTA} from "./ContactCTA";
import Button from "react-bootstrap/Button";

export function Results({answers, onSendInquiry}) {
  useEffect(() => {
    window.scrollTo({top: 0});
  }, []);
  const reasons = getRecommendations(answers);
  return (
    <div>
      <h1 className="mb-5 readable-30">
        <small>Projekt-Analyse</small><br/>
        Empfehlungen basierend auf Ihren Antworten
      </h1>
      <Card>
        {0 === reasons.length && (<Card.Body><NopeButSorry/></Card.Body>)}
        {reasons.length >= 0 && (
          <>
            <Card.Body>
              Es gibt für ihr geschildertes Szenario gute Gründe, eine individuelle Lösung anzustreben:
            </Card.Body>
            <Card.Body className="bg-light border-top">
              <ul>
                {reasons.map((reason, idx) => (<Reason key={idx} reason={reason}/>))}
              </ul>
            </Card.Body>
            <Card.Body className="border-top">
              <Button onClick={onSendInquiry}>Anfrage senden</Button>
            </Card.Body>
            <Card.Body className="border-top">
              <a href="/assessment">← Zurück</a>
            </Card.Body>
          </>
        )}
      </Card>
    </div>
  );
}
