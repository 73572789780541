import React from 'react';

export function NopeButSorry({}) {
  return (
    <>
      <p className="font-weight-bold">
        Es gibt für Ihr Szenario keinen guten Grund eine Individual-Lösung anzustreben.
        Mit einer gängigen Branchenlösung sind Sie gut beraten.
      </p>
      <p>
        Sollte sich bei zukünftigen Projekten wieder einmal diese Frage ergeben, würde ich mich freuen, wenn Sie
        sich an mich wenden würden.
      </p>
      <p className="mb-0">
        Für weitere Informationen können Sie natürlich gerne <a href="/kontakt">an mich wenden</a>.
      </p>
    </>
  );
}
