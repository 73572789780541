import React from 'react';
import {Question} from "./Question";
import {QuestionaireChapterHeader} from "./QuestionaireChapterHeader";

export function QuestionaireChapter({name, questions, answers, handleAnswer}) {
  return (
    <div className="card mb-4 bg-light">
      <QuestionaireChapterHeader name={name}/>
      {Object.keys(questions).map(questionKey => {
        const question = questions[questionKey];
        let hasConstraint = !!question.constraints;
        if (hasConstraint) {
          const passed = Object.keys(question.constraints)
            .map(constrainedQuestionKey => answers[constrainedQuestionKey] === question.constraints[constrainedQuestionKey])
            .reduce((passed, x) => passed && x, true);
          if (!passed) {
            return null;
          }
        }

        return (
          <Question
            key={questionKey}
            questionKey={questionKey}
            question={question}
            answer={answers[questionKey]}
            handleAnswer={handleAnswer}
            hasConstraint={hasConstraint}
          />
        );
      })}
    </div>
  );
}
