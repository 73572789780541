import anime from "animejs";

let timeline = null;

export function init() {
  initIfOK();

  window.addEventListener('resize', () => {
    initIfOK();
  });
}

const firedEvents = {};
let fireProgressTimeout = null;

function fireProgressEvent(progress) {
  if (firedEvents[progress]) {
    return;
  }
  firedEvents[progress] = true;
  if (null !== fireProgressTimeout) {
    clearTimeout(fireProgressTimeout)
  }
  fireProgressTimeout = setTimeout(() => {
    fetch(`/events?type=home_progress&payload[progress]=${progress}`, {
      method: 'POST',
      headers: {'Accept': 'application/json'}
    })
  }, 500);
}

function initIfOK() {
  if (window.innerWidth >= 375) {
    document.querySelectorAll('.landingpage__top-button').forEach(el => {
      el.addEventListener('click', () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
      })
    })
    initAnimation();
    scrollOnProgress();
  }
}

function initAnimation() {
  document.getElementById('landingpage').classList.add('landingpage--animated');
  let offset = 0;

  //--------------------------------------------------------------------------------------------------------------------
  // Slide: Intro
  //--------------------------------------------------------------------------------------------------------------------
  timeline = anime.timeline({
    direction: 'normal',
    loop: false,
    easing: 'linear',
    autoplay: false,
  });

  timeline.add({
    targets: '.landingpage__backdrop',
    scale: [1, 1.75],
    backgroundSize: ['100%', '200%'],
    duration: 3500,
  }, 0);

  timeline.add({
    targets: '#introSlide article',
    translateY: [0, '-130vh'],
    // opacity: [1, 0],
    duration: 1300,
  }, 0);

  timeline.add({
    targets: '.landingpage__backdrop',
    clipPath: ['circle(100%)', 'circle(0%)'],
    duration: 3500,
  }, 0);

  //--------------------------------------------------------------------------------------------------------------------
  // Slide: Individual software development
  //--------------------------------------------------------------------------------------------------------------------
  timeline.add({
    targets: '#inddevSlide',
    translateY: ['100vh', 0],
    opacity: [0, 1],
    duration: 2000,
    begin: () => fireProgressEvent('inddev')
  }, 2000);

  timeline.add({
    targets: '#inddevSlide',
    translateY: [0, '-400px'],
    duration: 2000,
  }, 4000);

  timeline.add({
    targets: '#inddevSlide',
    opacity: [1, 0],
    duration: 1000,
  }, 5000);

  //--------------------------------------------------------------------------------------------------------------------
  // Slide: Web development
  //--------------------------------------------------------------------------------------------------------------------
  timeline.add({
    targets: '#webdevSlide',
    translateY: ['100vw', 0],
    opacity: [0, 1],
    duration: 2000,
    begin: () => fireProgressEvent('webdev')
  }, 4000);

  timeline.add({
    targets: '#webdevSlide',
    translateY: [0, '-400px'],
    duration: 2000,
  }, 6000);

  timeline.add({
    targets: '#webdevSlide',
    opacity: [1, 0],
    duration: 1000,
  }, 7000);

  //--------------------------------------------------------------------------------------------------------------------
  // Slide Kunden
  //--------------------------------------------------------------------------------------------------------------------
  timeline.add({
    targets: '#customersSlide',
    translateY: ['100vh', 0],
    opacity: [0, 1],
    duration: 2000,
    begin: () => fireProgressEvent('customers')
  }, 6000);

  timeline.add({
    targets: '#customersSlide',
    translateY: [0, '-400px'],
    duration: 2000,
  }, 8000);

  timeline.add({
    targets: '#customersSlide',
    opacity: [1, 0],
    duration: 1000,
  }, 9000);

  //--------------------------------------------------------------------------------------------------------------------
  // Slide: Assessment
  //--------------------------------------------------------------------------------------------------------------------
  timeline.add({
    targets: '#assessmentSlide',
    translateY: ['100vw', 0],
    opacity: [0, 1],
    duration: 2000,
    begin: () => fireProgressEvent('assessment')
  }, 8000);

  timeline.add({
    targets: '#assessmentSlide',
    translateY: [0, '-400px'],
    duration: 2000,
  }, 10000);

  timeline.add({
    targets: '#assessmentSlide',
    opacity: [1, 0],
    duration: 1000,
  }, 11000);

  //--------------------------------------------------------------------------------------------------------------------
  // Slide 3 start
  //--------------------------------------------------------------------------------------------------------------------
  timeline.add({
    targets: '#contactSlide',
    translateY: ['100vh', 0],
    duration: 2000,
    begin: () => fireProgressEvent('contact')
  }, 10000);

  timeline.add({
    targets: '#contactSlide .staggered',
    translateY: [100, 0],
    opacity: [0, 1],
    delay: anime.stagger(350),
    duration: 350,
  }, 12000);

  timeline.add({
    targets: '#contactSlide',
    translateY: [0, '-280px'],
    duration: 1000,
  }, 12500);
}

function handleScrollOnProgress(e) {
  seek();
}

function scrollOnProgress() {
  window.removeEventListener('scroll', handleScrollOnProgress);
  window.addEventListener('scroll', handleScrollOnProgress);
}

function seek() {
  timeline.seek(getScrollProgress() * timeline.duration);
}

function getScrollProgress() {
  return window.scrollY / (document.body.scrollHeight - window.innerHeight);
}

