import React, {useEffect, useState} from 'react';
import {questionaire} from "./questionnaire";
import Button from "react-bootstrap/Button";
import {QuestionaireChapter} from "./QuestionaireChapter";
import {IntroText} from "./IntroText";
import {Results} from "./Results";
import {ContactForm} from "../contact_form/ContactForm";

const initialAnswers = {};
const storageKey = 'selfassessment-answers-v1';

export function Assessment({}) {
  const [answers, setAnswers] = useState(initialAnswers);
  const [submitted, setSubmitted] = useState(false);
  const [sendInquiry, setSendInquiry] = useState(false);
  const handleAnswer = (attr, value) => {
    const newAnswers = {...answers, [attr]: value,}
    setAnswers(newAnswers);
    localStorage.setItem(storageKey, JSON.stringify(newAnswers));
  };

  useEffect(() => {
    const loadedAnswers = localStorage.getItem(storageKey);
    if (null !== loadedAnswers) {
      setAnswers(JSON.parse(loadedAnswers));
    }
  }, []);

  useEffect(() => {
    const step = !submitted ? 'questionaire' : !sendInquiry ? 'results' : 'contact';
    return fetch(`/events?type=assessment_step&payload[step]=${step}`, {method: 'POST', headers: {'Accept': 'application/json'}});
  }, [submitted, sendInquiry]);

  return (
    <div>
      {!submitted && <React.Fragment>
        <IntroText/>

        {Object.keys(questionaire).map(chapterKey => (
          <QuestionaireChapter
            key={chapterKey}
            name={questionaire[chapterKey].name}
            questions={questionaire[chapterKey].questions}
            answers={answers}
            handleAnswer={handleAnswer}
          />
        ))}
        <div className="text-right">
          <Button variant="primary" size="lg" onClick={() => setSubmitted(true)}>
            Weiter zur Auswertung →
          </Button>
        </div>
      </React.Fragment>}
      {submitted && !sendInquiry && <Results answers={answers} onSendInquiry={() => setSendInquiry(true)}/>}
      {submitted && sendInquiry && (
        <>
          <h1 className="mb-5 readable-30">
            <small>Projekt-Analyse</small><br/>
            Nächste Schritte
          </h1>
          <div className="readable-65 mb-5">
            <p>
              <strong>Gerne würde ich mit Ihnen über Ihr Projekt sprechen.</strong>
            </p>
            <p>
              Wenn Sie mir mit dem folgende Kontaktformular Ihre Projektinformationen und ggf. weitere Informationen
              zukommen lassen, melde ich mich gerne bei Ihnen, um Ihnen konkrete Möglichkeiten vorzuschlagen.
            </p>
          </div>
          <div className="readable-65">
            <ContactForm answers={answers}/>
          </div>
        </>
      )}
    </div>
  );
}
