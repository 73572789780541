import React from 'react';
import Form from "react-bootstrap/Form";
import {Col} from "react-bootstrap";
import {YesnodunnoControl} from "./controls/YesnodunnoControl";
import {RangeControl} from "./controls/RangeControl";
import {RadioControl} from "./controls/RadioControl";

export function Question({questionKey, question, handleAnswer, answer, hasConstraint}) {
  return (
    <div className={`card-body border-top ${hasConstraint ? 'pl-6' : ''}`}>
      <Form.Group controlId={questionKey}>
        <Form.Row>
          <Col md={6} lg={7} xl={8}>
            <Form.Label>
              <div className="readable-65">
                {hasConstraint && <i className="bi bi-arrow-return-right mr-2"/>}
                {question.question}
              </div>
            </Form.Label>
          </Col>
          <Col md={6} lg={5} xl={4}>
            {'range' === question.control && (
              <RangeControl
                handleAnswer={handleAnswer}
                answer={answer}
                questionKey={questionKey}
              />
            )}
            {'yesnodunno' === question.control && (
              <YesnodunnoControl
                handleAnswer={handleAnswer}
                answer={answer}
                questionKey={questionKey}
              />
            )}
            {'radio' === question.control && (
              <RadioControl
                handleAnswer={handleAnswer}
                answer={answer}
                questionKey={questionKey}
                options={question.options}
              />
            )}
          </Col>
        </Form.Row>
      </Form.Group>
    </div>
  );
}
