import React from 'react';

export function ContactCTA({}) {
  return (
    <>
      <div className="readable-65 mb-3">
        <strong>
          Benötigen Sie einen Partner bei der Digitalisierung ihrer Geschäftsprozesse oder der Umsetzung
          eines digitalen Produkts?
        </strong>
      </div>
      <div className="mb-3">
        <a className="btn btn-primary" href="/kontakt">
          <i className="bi bi-chat-left-fill "/>
          {' '}
          Fragen Sie mich an
        </a>
      </div>
      <div className="readable-65">
        In einem persönlichen Gespräch informiere ich Sie gerne über Möglichkeiten zu Förderung und Durchführung Ihres Vorhabens.
      </div>
    </>
  );
}
