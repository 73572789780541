import React from "react";

export const humanValues = {
  '': 'keine Angaben',
  '0': 'Nein',
  '1': 'Ja',
  irrelevant: 'Irrelevant',
  geringerMehrwert: 'Geringer Mehrwert',
  neutral: 'Neutral',
  deutlicherMehrwert: 'Deutlicher Mehrwert',
  unverzichtbar: 'Unverzichtbar',
  lessThan10: '1 - 9',
  lessThan50: '10 - 49',
  lessThan1000: '50 - 999',
  atLeast1000: '1000 oder mehr',
  unknown: 'keine Angaben',
  below5k: 'unter 5.000 €',
  below10k: 'unter 10.000 €',
  below50k: 'unter 50.000 €',
  below100k: 'unter 100.000 €',
  atLeast100k: '100.000 € oder mehr',
  employees1to2: '1 - 2',
  employees3to499: '3 - 499',
  employees500plus: '500 oder mehr',
  costsUpto5: 'weniger als 5 € / Benutzer',
  costsUpto20: 'weniger als 20 € / Benutzer',
  costsUpto100: 'weniger als 100 € / Benutzer',
  costsUpto500: 'weniger als 500 € / Benutzer',
  costsUpto2000: 'weniger als 2000 € / Benutzer',
  costsAbove2000: 'mindestens 2000 € / Benutzer',
  webauftritt: 'Web-Auftritt',
  answendungOderSaas: 'Anwendungssoftware und/oder SaaS',
  mobileBackend: 'Back-End für Mobile-Apps',
};

export const questionaire = {
  //------------------------------------------------------------------------------------------------------------------
  goals: {
    name: 'Zielsetzung',
    questions: {
      category: {
        question: 'Welche der folgenden Kategorien beschreibt Ihre angestrebte Lösung am besten?',
        control: 'radio',
        options: {
          webauftritt: 'Web-Auftritt',
          answendungOderSaas: 'Anwendungssoftware und/oder SaaS',
          mobileBackend: 'Back-End für Mobile-Apps',
        },
        recommend: (answer, allAnswers) => {
          if (answer === 'answendungOderSaas') {
            return ['Anwendungssoftware bzw. Software-as-a-Service erfordern in der Regel eine langfristige Entwicklungsarbeit, die auf kontinuierliche Verbesserung ausgelegt ist. Eine individual-Entwicklung ist dabei fast unumgänglich.'];
          } else if (answer === 'mobileBackend') {
            return ['In speziellen Anwendungsfällen existieren zwar Standard-Lösungen für Mobile-Backends, diese sind jedoch meist stark auf ein sehr präzises Szenario ausgelegt. Bei kurzfristigen Projekten kann das sinnvoll sein. Sollten Sie eine langfristige Entwicklung ihres Produkts oder Service anstreben, sollten Sie eine individuelle Lösung in Betracht ziehen.'];
          }
        }
      },
      coreCompetenceFactor: {
        question: 'Welche Relevanz hat das Vorhaben für Ihr Kerngeschäft?',
        control: 'range',
        recommend: (answer, allAnswers) => {
          if (answer === 'neutral' || answer === 'deutlicherMehrwert') {
            return ['Mit Standard-Software machen Sie sich immer ein Stück weit abhängig von einem Anbieter. Da ihr Vorhaben nicht unerheblich für ihr Kerngeschäft ist, sollten Sie eine individuelle Lösung zumindest in Betracht ziehen.'];
          } else if (answer === 'unverzichtbar') {
            return ['Mit Standard-Software machen Sie sich immer ein Stück weit abhängig von einem Anbieter. Da das beschrieben Vorhaben unverzichtbar für ihr Kerngeschäft ist, scheint in jedem Fall eine individuelle Lösung notwendig zu sein.'];
          }
        },
      },
    },
  },
  //------------------------------------------------------------------------------------------------------------------
  priorities: {
    name: 'Prioritäten',
    questions: {
      innovationSafetyFactor: {
        question: 'Wie wichtig ist für Sie die Investitionssicherheit (Verfügbarkeit von Quellcode und Dokumentation)?',
        control: 'range',
        recommend: (answer, allAnswers) => {
          if (answer === 'neutral' || answer === 'deutlicherMehrwert') {
            return ['Da Investitionssicherheit für das beschriebene Vorhaben relevant ist, sollten Sie eine individuelle Lösung zumindest in Betracht ziehen, um sich nicht von einem bestimmten Anbieter abhängig zu machen.'];
          } else if (answer === 'unverzichtbar') {
            return ['Da Investitionssicherheit für das beschriebene Vorhaben essenziell ist, scheint in jedem Fall eine individuelle Lösung notwendig zu sein, um sich nicht von einem bestimmten Anbieter abhängig zu machen.'];
          }
        },
      },
      featureControlFactor: {
        question: 'Wie wichtig ist Ihnen die Kontrolle über die weitere Entwicklung der Software?',
        control: 'range',
        recommend: (answer, allAnswers) => {
          if (answer === 'neutral' || answer === 'deutlicherMehrwert') {
            return ['Da die Kontrolle über die weitere Entwicklung ihrer Software für das beschriebene Vorhaben relevant ist, sollten Sie eine individuelle Lösung zumindest in Betracht ziehen, um sich nicht von einem bestimmten Anbieter abhängig zu machen.'];
          } else if (answer === 'unverzichtbar') {
            return ['Da die Kontrolle über die weitere Entwicklung ihrer Software für das beschriebene Vorhaben essenziell ist, sollten Sie eine individuelle Lösung in Betracht ziehen, um sich nicht von einem bestimmten Anbieter abhängig zu machen.'];
          }
        },
      },
      vendorIndependanceFactor: {
        question: 'Wie wichtig ist Ihnen die Unabhängigkeit von Software- oder Plattform-Anbietern? (Vendor-Lock-In)',
        control: 'range',
        recommend: (answer, allAnswers) => {
          if (answer === 'neutral' || answer === 'deutlicherMehrwert' || answer === 'unverzichtbar') {
            return ['Die Geschäftsmodelle vieler Technologieunternehmen zielen darauf ab, Kunden durch den Zugang zu ihren eigenen Daten zu binden. Mit einer Individual-Software drehen Sie den Spieß um und behalten Sie die Kontrolle über ihre Geschäftsdaten. Dienste von Drittanbietern können meist trotzdem über Schnittstellen integriert werden, da diese in der Regel für eingehende Inhalte offen sind und lediglich den Abzug von Daten erschweren.'];
          }
        },
      },
      stakeholderAcceptanceFactor: {
        question: 'Welche Relevanz hat die Akzeptanz aller Stakeholder für Ihr Vorhaben?',
        control: 'range',
        recommend: (answer, allAnswers) => {
          if (answer === 'neutral' || answer === 'deutlicherMehrwert' || answer === 'unverzichtbar') {
            return ['Ihm Rahmen einer Eigenentwicklung kann eine Anforderungsanalyse mit allen Benutzergruppen und Teilhabern stattfinden, die über den gesamten Lebenszyklus des Produkts erneuert werden kann. Mit den gesammelten Anforderungen als Umsetzungsbasis kann die Akteptanz aller Beteiligter erhöht werden.'];
          }
        },
      },
    },
  },
  //------------------------------------------------------------------------------------------------------------------
  environment: {
    name: 'Unternehmen, Branche & Umfeld',
    questions: {
      employees: {
        question: 'Wieviele Mitarbeiter hat Ihr Unternehmen?',
        control: 'radio',
        options: {
          employees1to2: '1 - 2',
          employees3to499: '3 - 499',
          employees500plus: '500 oder mehr',
        },
        recommend: (answer, allAnswers) => {
          if (answer === 'employees3to499') {
            return ['Sie erfüllen die Voraussetzungen für eine Förderung. Gerne berate ich Sie hierzu.'];
          }
        },
      },
      marketEntry: {
        question: 'Besteht Ihr Unternehmen bereits über 2 Jahre?',
        control: 'yesnodunno',
        recommend: (answer, allAnswers) => {
          if (answer === '0') {
            return ['Sie erfüllen die Voraussetzungen für eine Förderung. Gerne berate ich Sie hierzu.'];
          }
        },
      },
      nonprofit: {
        question: 'Handelt es sich um ein gemeinnütziges Unternehmen, einen gemeinnützigen Verein oder eine Stiftung?',
        control: 'yesnodunno',
        recommend: (answer, allAnswers) => [],
      },
      standardSoftwareExists: {
        question: 'Existiert eine etablierte Standard-Software, die Ihre Anforderungen behandelt?',
        control: 'yesnodunno',
        recommend: (answer, allAnswers) => [],
      },
      standardSoftwareSatisficationFactor: {
        question: 'Werden Ihre Anforderungen von dieser Standard-Software zufriedenstellend erfüllt?',
        control: 'yesnodunno',
        constraints: {
          standardSoftwareExists: '1',
        },
        recommend: (answer, allAnswers) => {
          if (answer === '0') {
            return ['Da zwar eine gängige Standard-Software in ihrem Bereich besteht, diese aber nicht alle Anforderungen erfüllt, könnte eine individuelle Erweiterung der bestehenden Lösung ein sinnvoller und kosteneffizienter Weg sein. Gerne berate ich Sie.'];
          }
        },
      },
      standardSoftwareAcceptanceFactor: {
        question: 'Besitzt diese Standard-Software eine hohe Akzeptanz bei den Zielgruppen (vgl. Outlook bei E-Mailanwendern)?',
        control: 'yesnodunno',
        constraints: {
          standardSoftwareExists: '1',
        },
        recommend: (answer, allAnswers) => {
          if (answer === '0') {
            return [
              'Eventuell ergibt sich hier eine Chance für Sie, da es zwar eine etablierte Branchen-Software gibt, diese aber wenig Akzeptanz besitzt.',
              'Für bestehende Standard-Software mit geringer Akzeptanz kann eventuell ein alternatives Fronend entwicklelt werden, dass besser auf Ihre Zielgruppe zugeschnitten ist.',
            ];
          }
        },
      },
      standardSoftwareCosts: {
        question: 'Welche jährlichen Kosten entstünden durch die Standard-Software in etwa (Anschaffungs-/Abokosten, Betrieb, Updates, Schulung, etc.)?',
        control: 'radio',
        options: {
          unknown: 'Keine Angaben',
          costsUpto5: 'weniger als 5 € / Benutzer',
          costsUpto20: 'weniger als 20 € / Benutzer',
          costsUpto100: 'weniger als 100 € / Benutzer',
          costsUpto500: 'weniger als 500 € / Benutzer',
          costsUpto2000: 'weniger als 2000 € / Benutzer',
          costsAbove2000: 'mindestens 2000 € / Benutzer',
        },
        constraints: {
          standardSoftwareExists: '1',
        },
        recommend: (answer, allAnswers) => {
          let isCostIneffective = false;

          if (['below50k', 'below100k', 'atLeast100k'].includes(answer)
            && ['costsUpto100', 'costsUpto500', 'costsUpto2000', 'costsAbove2000'].includes(answer)
            && ['lessThan50', 'lessThan1000', 'atLeast1000'].includes(allAnswers.estimatedUsers)) {
            isCostIneffective = true;

          } else if (['below50k', 'below100k', 'atLeast100k'].includes(answer)
            && ['costsUpto500', 'costsUpto2000', 'costsAbove2000'].includes(answer)
            && ['lessThan1000', 'atLeast1000'].includes(allAnswers.estimatedUsers)) {
            isCostIneffective = true;
          }

          if (isCostIneffective) {
            return [
              'Mit der etablierten Standardsoftware entstehen relativ hohe Kosten und die Chancen stehen gut, dass es eine kostengünstigere, individuell auf Sie zugeschnittene Alternative gibt.',
            ];
          }
        },
      },
    },
  },
  //------------------------------------------------------------------------------------------------------------------
  integration: {
    name: 'Integration',
    questions: {
      estimatedUsers: {
        question: 'Wieviele gleichzeitige Anwender erwarten Sie etwa?',
        control: 'radio',
        options: {
          lessThan10: '1 - 9',
          lessThan50: '10 - 49',
          lessThan1000: '50 - 999',
          atLeast1000: '1000 oder mehr',
        },
        recommend: (answer, allAnswers) => []
      },
      trainingRequired: {
        question: 'Erachten Sie eine Schulung der Nutzer für notwendig bzw. praktikabel?',
        control: 'yesnodunno',
        recommend: (answer, allAnswers) => {
          if (answer === '1') {
            return ['Werden die Benutzer in die Anforderungsanalyse einer Individualentwicklung einbezogen, so steigt die Akzeptanz sowie die Zugänglich und Schulungsaufwände werden reduziert.'];
          }
        },
      },
      integrationRequired: {
        question: 'Existieren Drittsysteme innerhalb Ihres Unternehmens (Authentifizierung, Datenbanken, etc.), die eventuell integriert bzw. angebunden werden müssen?',
        control: 'yesnodunno',
        recommend: (answer, allAnswers) => {
          if (answer === '1') {
            return ['Die Anbindung von Drittsystemen gestaltet sich bei Standardsoftware oft problematisch.'];
          }
        },
      },
    },
  },
  //------------------------------------------------------------------------------------------------------------------
  budget: {
    name: 'Projektrahmen',
    questions: {
      budget: {
        question: 'In welchem Budgetrahmen muss eine Lösung liegen?',
        control: 'radio',
        options: {
          unknown: 'keine Angaben',
          below5k: 'unter 5.000 €',
          below10k: 'unter 10.000 €',
          below50k: 'unter 50.000 €',
          below100k: 'unter 100.000 €',
          atLeast100k: '100.000 € oder mehr',
        },
        recommend: (answer, allAnswers) => {
        },
      },
    },
  },
}

function eachQuestion(cb) {
  let idx = 0;
  Object.keys(questionaire).forEach(chapterKey => {
    Object.keys(questionaire[chapterKey].questions).forEach(questionKey => {
      cb(questionKey, questionaire[chapterKey].questions[questionKey], chapterKey, idx++);
    });
  });
}

export function getRecommendations(answers) {
  let recommendations = [];
  eachQuestion((questionKey, question, chapterKey, i) => {
    const answer = answers[questionKey];
    if (undefined !== answer) {
      const questionRecommendations = question.recommend(answer, answers);
      if (questionRecommendations) {
        recommendations = recommendations.concat(questionRecommendations);
      }
    }
  });
  return recommendations;
  // return [...new Set(recommendations)] // es ist eigentlich eine gute Sache, wenn die vage Andeutung auf Förderung mehrmals erscheint.
}
