// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React from 'react'
import ReactDOM from 'react-dom'
import {ContactForm} from "../src/contact_form/ContactForm";
import {Assessment} from "../src/assessment/Assessment";
import '../stylesheets/application.scss';
import {init as initHomepageAnimation} from "../src/homepage/animation";

function init() {
  document.querySelectorAll('[data-bsw-assessment]').forEach(el =>
    ReactDOM.render(<Assessment/>, el));

  document.querySelectorAll('[data-bsw-contact-form]').forEach(el =>
    ReactDOM.render(<ContactForm/>, el));

  if (document.getElementById('landingpage')) {
    initHomepageAnimation();
  }
}

// TODO: In FF scrolling jumps unexplainably backwards after the first offset scroll. Doesn't happen with DOMContentLoaded
// document.addEventListener('turbolinks:load', init);
// require("turbolinks").start();
document.addEventListener('DOMContentLoaded', init);
