import PropTypes from "prop-types";
import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import {humanValues, questionaire} from "../assessment/questionnaire";

const initialState = {
  name: '',
  email: '',
  message: '',
  agree_to_terms: false,
  answers: {},
};

function send({name, email, message, answers, includeAnswers, agree_to_terms}) {
  const formData = new FormData();
  formData.append('message[name]', name);
  formData.append('message[email]', email);
  formData.append('message[message]', message);
  formData.append('message[agree_to_terms]', agree_to_terms ? '1' : '0');

  if (includeAnswers) {
    formData.append('message[answers]', assembleAnswers(answers).join("\n\n"));
  }

  return fetch(`/messages`, {
    method: 'POST',
    body: formData,
    headers: {'Accept': 'application/json'},
  }).then(response => response.json());
}

function assembleAnswers(answers) {
  if (!answers) {
    return [];
  }

  const flatQuestions = Object.keys(questionaire).reduce((memo, q) => {
    memo = {...memo, ...questionaire[q].questions};
    return memo;
  }, {});

  return Object.keys(answers).reduce((memo, questionKey) => {
    if (flatQuestions[questionKey]) {
      if (undefined === humanValues[answers[questionKey]]) {
        console.log("ContactForm.jsx:45: NOT TRANSLATED: ", questionKey, answers[questionKey]);
      }
      memo.push(`Q: ${flatQuestions[questionKey].question}\nA: ${humanValues[answers[questionKey]]}`);
    }
    return memo;
  }, []);
}

export function ContactForm(props) {
  const {answers} = props;
  const [state, setState] = useState({...initialState, answers});
  const [sent, setSent] = useState(false);
  const [errors, setErrors] = useState({});
  const [includeAnswers, setIncludeAnswers] = useState(true);

  const handleChange = (name, value) => {
    setState({...state, [name]: value});
  };

  console.log("ContactForm.jsx:59: ", answers);
  const assembledAnswers = assembleAnswers(answers);

  const handleSubmit = e => {
    e.preventDefault();
    send({...state, includeAnswers, answers}).then(json => {
      if (Object.keys(json.errors).length > 0) {
        setErrors(json.errors);
      } else {
        setErrors({});
        setState({...initialState, name: state.name, email: state.email})
        setSent(true);
      }
    });
  }

  return (
    <React.Fragment>
      {sent && <div>
        <div className="card card-primary">
          <div className="mb-3">
            Vielen Dank für Ihre Nachricht.
          </div>
          <a className="btn btn-primary" onClick={() => setSent(false)}>
            Eine weitere Nachricht senden
          </a>
        </div>
      </div>}

      {!sent && <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div className={formGroupClassName(errors, 'name')}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              autoComplete="name"
              autoFocus={true}
              name="contact[name]"
              value={state.name}
              placeholder="..."
              className="form-control"
              onChange={(e) => handleChange('name', e.currentTarget.value)}
            />
            {errors.name && errors.name.map(error => <div key={error} className="error">{error}</div>)}
          </div>

          <div className={formGroupClassName(errors, 'email')}>
            <label htmlFor="name">E-Mail</label>
            <input
              type="text"
              autoComplete="email"
              name="contact[email]"
              value={state.email}
              placeholder="..."
              className="form-control"
              onChange={(e) => handleChange('email', e.currentTarget.value)}
            />
            {errors.email && errors.email.map(error => <div key={error} className="error">{error}</div>)}
          </div>

          {answers && Object.keys(answers).length > 0 && (
            <>
              <div className="mb-3">
                <Form.Check
                  custom
                  type="checkbox"
                  id="sendAnswers"
                  value="includeAnswers"
                  checked={includeAnswers}
                  onChange={() => setIncludeAnswers(!includeAnswers)}
                  label="Sie planen ein Projekt mit folgenden Rahmenbedingungen und möchten gerne weitere Informationen erhalten."
                />
              </div>
              <div className="card card-body bg-light p-0 mb-3" style={{whiteSpace: 'pre', fontSize: '0.75rem', maxHeight: 200, overflow: 'auto'}}>
                {assembledAnswers.map((answer) => (
                  <div key={answer} className="mb-1 py-1 px-3 border-bottom">
                    {answer}
                  </div>
                ))}
              </div>
            </>
          )}

          <div className={formGroupClassName(errors, 'message')}>
            <label htmlFor="name">Nachricht</label>
            <textarea
              rows="10"
              name="contact[message]"
              value={state.message}
              placeholder="..."
              className="form-control"
              onChange={(e) => handleChange('message', e.currentTarget.value)}
            />
            {errors.message && errors.message.map(error => <div key={error} className="error">{error}</div>)}
          </div>

          <div className={formGroupClassName(errors, 'agree_to_terms')}>
            <div className="d-flex">
              <Form.Check
                custom
                type="checkbox"
                label=""
                onChange={e => handleChange('agree_to_terms', e.currentTarget.checked)}
                id="agreeToTerms"
              />
              <div className="form-control-help">
                <i>
                  Die <a href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> haben Sie zur Kenntnis
                  genommen und stimmen zu, dass Ihre Daten <strong>zur
                  Beantwortung Ihrer Anfrage</strong> elektronisch erhoben und gespeichert werden.
                </i>
              </div>
            </div>
            {errors.agree_to_terms && errors.agree_to_terms.map(error => (
              <div key={error} className="error">{error}</div>
            ))}
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Senden
            </button>
          </div>
        </form>
      </React.Fragment>}
    </React.Fragment>
  );
}

function formGroupClassName(errors, attr) {
  const parts = ['form-group'];
  if (errors[attr]) {
    parts.push(' invalid');
  }
  return parts.join(' ');
}

ContactForm.defaultProps = {}

ContactForm.propTypes = {
  // name: PropTypes.string
}

